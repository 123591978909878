<template>
	<v-app id="app">
		<Main :style="cssVars" :filter-typology="arrayFilterTypology"></Main>
	</v-app>
</template>
<script>
import Main from '@/views/Main.vue'
import { mapMutations } from 'vuex'
import store from './store'

export default {
	name: 'App',
	components: {
		Main,
	},
	props: {
		version: { type: Number, default: 1 },
		projectId: { type: Number, default: null },
		realEstate: { type: String, default: null },
		countryCode: { type: String, default: 'CL' },
		typology: { type: String, default: null },
		roomsAmount: { type: Number, default: null },
		bathroomsAmount: { type: Number, default: null },
		ageRangeRequired: { type: Boolean, default: null },
		destinationPurchaseRequired: { type: Boolean, default: null },
		communeRequired: { type: Boolean, default: null },
		professionRequired: { type: Boolean, default: null },
		sexRequired: { type: Boolean, default: null },
		rentRangeRequired: { type: Boolean, default: null },
		observationsRequired: { type: Boolean, default: null },
		quoteSuccessAndErrorAsDialog: { type: Boolean, default: false },
		customObservationsQuestion: { type: String, default: null },
		useSecondaryImage: { type: Boolean, default: false },
		useThirdImage: { type: Boolean, default: false },
		assetId: { type: Number, default: null },
		color1: { type: String, default: null },
		color2: { type: String, default: null },
		color3: { type: String, default: null },
		inputColor1: { type: String, default: null },
		inputColor2: { type: String, default: null },
		selectColor: { type: String, default: null },
		selectTextColor: { type: String, default: null },
		infoAlertColor: { type: String, default: null },
		infoAlertTextColor: { type: String, default: null },
		buttonTextColor: { type: String, default: null },
		totalColor: { type: String, default: null },
		validationColor: { type: String, default: null },
		warehousesMaxNumber: { type: Number, default: null },
		warehousesMinNumber: { type: Number, default: null },
		parkingLotsMaxNumber: { type: Number, default: null },
		parkingLotsMinNumber: { type: Number, default: null },
		showRealEstateLogo: { type: Boolean, default: true },
		showProjectLogo: { type: Boolean, default: true },
		hideSelectedAssets: { type: Boolean, default: false },
		realEstateLogoUrl: { type: String, default: null },
		template: { type: String, default: '' },
		successViewType: { type: String, default: 'simple' },
		quoteLimit: { type: Number, default: null },
		utmCampaign: { type: String, default: null },
		utmContent: { type: String, default: null },
		utmMedium: { type: String, default: null },
		utmSource: { type: String, default: null },
		utmTerm: { type: String, default: null },
		urlSuccess: { type: String, default: null },
		reservation: { type: Boolean, default: false },
		secondaryAssets: { type: String, default: null },
		hideProjectData: { type: Boolean, default: false },
		source: { type: String, default: null },
		contact: { type: String, default: null },
		typologyByEvent: { type: Boolean, default: false },
		assetType: { type: String, default: null },
		projectLogoUrl: { type: String, default: null },
		receiveNews: { type: Boolean, default: false },
		description: { type: String, default: null },
		filterTypology: { type: String, default: null },
		orientationOnList: { type: Boolean, default: false },
		urlQualified: { type: String, default: null },
		urlNotQualified: { type: String, default: null },
		rentToQualify: { type: String, default: null },
		terms: { type: Boolean, default: false },
		termsText: { type: String, default: 'términos y privacidad' },
		termsUrl: { type: String, default: null },
	},
	data() {
		return {
			arrayFilterTypology: [],
		}
	},
	computed: {
		cssVars() {
			return {
				'--color1': this.color1,
				'--color2': this.color2,
				'--color3': this.color3,
				'--input-color1': this.inputColor1,
				'--select-color': this.selectColor,
				'--select-text-color': this.selectTextColor,
				'--info-alert-color': this.infoAlertColor,
				'--info-alert-text-color': this.infoAlertTextColor,
				'--button-text-color': this.buttonTextColor,
				'--total-color': this.totalColor,
				'--validation-color': this.validationColor,
			}
		},
	},
	created() {
		this.setPropertyNames(this.version)
		this.setTemplate(this.template)
		this.setVersion(this.version)
		this.setProjectId(this.projectId)
		this.setRealEstate(this.realEstate)
		this.setShowRealEstateLogo(this.showRealEstateLogo)
		this.setShowProjectLogo(this.showProjectLogo)
		this.setRealEstateLogo(this.realEstateLogoUrl)
		this.setCustomProjectLogo(this.projectLogoUrl)
		this.setQuoteSuccessAndErrorAsDialog(this.quoteSuccessAndErrorAsDialog)
		this.setCountry(this.countryCode)
		this.setTypology(this.typology)
		this.setRoomsAmount(this.roomsAmount)
		this.setBathroomsAmount(this.bathroomsAmount)
		this.setOrientationOnList(this.orientationOnList)
		this.setTerms(this.terms)
		this.setTermsText(this.termsText)
		this.setTermsUrl(this.termsUrl)
		if (this.useSecondaryImage) {
			this.setImagePreference({
				field: 'useSecondaryImage',
				value: true,
			})
		} else if (this.useThirdImage) {
			this.setImagePreference({
				field: 'useThirdImage',
				value: true,
			})
		}
		this.setAssetIdParam(this.assetId)
		this.setThemeColors({
			color1: this.color1,
			color2: this.color2,
			color3: this.color3,
			inputColor1: this.inputColor1,
			inputColor2: this.inputColor2,
			selectColor: this.selectColor,
			selectTextColor: this.selectTextColor,
			infoAlertColor: this.infoAlertColor,
			infoAlertTextColor: this.infoAlertTextColor,
			buttonTextColor: this.buttonTextColor,
			totalColor: this.totalColor,
			validationColor: this.validationColor,
		})

		// If null, do not render the field, otherwise the value sets the field to
		// to be required or not.
		if (this.receiveNews) {
			this.setOptionalField({
				field: 'receiveNews',
				isSet: true,
				isRequired: false,
			})
		}
		this.setOptionalField({
			field: 'ageRange',
			isSet: this.ageRangeRequired != null ? this.ageRangeRequired : null,
			isRequired: this.ageRangeRequired,
		})

		this.setOptionalField({
			field: 'destinationPurchase',
			isSet:
				this.destinationPurchaseRequired != null
					? this.destinationPurchaseRequired
					: null,
			isRequired: this.destinationPurchaseRequired,
		})

		this.setOptionalField({
			field: 'commune',
			isSet: this.communeRequired != null ? this.communeRequired : null,
			isRequired: this.communeRequired,
		})

		this.setOptionalField({
			field: 'profession',
			isSet: this.professionRequired != null ? this.professionRequired : null,
			isRequired: this.professionRequired,
		})

		this.setOptionalField({
			field: 'sex',
			isSet: this.sexRequired != null ? this.sexRequired : null,
			isRequired: this.sexRequired,
		})

		this.setOptionalField({
			field: 'rentRange',
			isSet: this.rentRangeRequired != null ? this.rentRangeRequired : null,
			isRequired: this.rentRangeRequired,
		})

		this.setOptionalField({
			field: 'observations',
			isSet:
				this.observationsRequired != null ? this.observationsRequired : null,
			isRequired: this.observationsRequired,
		})

		this.setCustomObservationsQuestion(this.customObservationsQuestion)
		this.setSuccessViewType(this.successViewType)
		this.setWarehousesMaxNumber(this.warehousesMaxNumber)
		this.setParkingLotsMaxNumber(this.parkingLotsMaxNumber)
		this.setParkingLotsMinNumber(this.parkingLotsMinNumber)
		this.setWarehousesMinNumber(this.warehousesMinNumber)
		this.setQuoteLimit(this.quoteLimit)
		this.setDescription(this.description)
		this.setHideSelectedAssets(this.hideSelectedAssets)
		this.setProjectDataVisibility(this.hideProjectData)
		this.setUtmData({
			utmCampaign: this.utmCampaign
				? this.utmCampaign
				: this.findGetParameter('utm_campaign'),
			utmContent: this.utmContent
				? this.utmContent
				: this.findGetParameter('utm_content'),
			utmMedium: this.utmMedium
				? this.utmMedium
				: this.findGetParameter('utm_medium'),
			utmSource: this.utmSource
				? this.utmSource
				: this.findGetParameter('utm_source'),
			utmTerm: this.utmTerm ? this.utmTerm : this.findGetParameter('utm_term'),
		})
		this.setReservation(this.reservation)
		this.setSecondaryAssets(this.secondaryAssets)
		this.setTypologyByEvent(this.typologyByEvent)
		this.setAssetType(this.assetType)
		this.setRentToQualify(this.rentToQualify)
		if (this.findGetParameter('source'))
			this.setSource(this.findGetParameter('source'))
		else this.setSource(this.source)
		if (this.findGetParameter('contact'))
			this.setContact(this.findGetParameter('contact'))
		else this.setContact(this.contact)
		if (this.filterTypology) {
			this.arrayFilterTypology = this.filterTypology.split(',')
		}

		//Validate URL
		try {
			let url = new URL(this.urlSuccess)
			this.setUrlSuccess(url)
		} catch (_) {
			//console.log('url-success not defined')
		}

		try {
			let urlQualified = new URL(this.urlQualified)
			this.setUrlQualified(urlQualified)
			let urlNotQualified = new URL(this.urlNotQualified)
			this.setUrlNotQualified(urlNotQualified)
		} catch (_) {
			//console.log('url-success not defined')
		}
	},
	methods: {
		...mapMutations('general', [
			'setAssetIdParam',
			'setTemplate',
			'setVersion',
			'setProjectId',
			'setRealEstate',
			'setTypology',
			'setRoomsAmount',
			'setBathroomsAmount',
			'setQuoteSuccessAndErrorAsDialog',
			'setWarehousesMaxNumber',
			'setParkingLotsMaxNumber',
			'setParkingLotsMinNumber',
			'setWarehousesMinNumber',
			'setQuoteLimit',
			'setUtmData',
			'setReservation',
			'setRealEstateLogo',
			'setShowRealEstateLogo',
			'setShowProjectLogo',
			'setSecondaryAssets',
			'setSecondaryAssets',
			'setHideSelectedAssets',
			'setTypologyByEvent',
			'setAssetType',
			'setCustomProjectLogo',
			'setDescription',
			'setOrientationOnList',
		]),
		...mapMutations('form', [
			'setCountry',
			'setOptionalField',
			'setCustomObservationsQuestion',
			'setSource',
			'setContact',
			'setRentToQualify',
			'setTerms',
			'setTermsText',
			'setTermsUrl',
		]),
		...mapMutations('assets', ['setPropertyNames', 'setImagePreference']),
		...mapMutations('theme', [
			'setThemeColors',
			'setSuccessViewType',
			'setUrlSuccess',
			'setUrlQualified',
			'setUrlNotQualified',
			'setProjectDataVisibility',
		]),
		findGetParameter(parameterName) {
			var result = null,
				tmp = []
			location.search
				.substr(1)
				.split('&')
				.forEach(function(item) {
					tmp = item.split('=')
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
				})
			return result
		},
	},
}
window.addEventListener('load', () => {
	document.addEventListener('set-typology', setTypologyByExternalEvent, false)
	document.addEventListener('set-floor', setFloorByExternalEvent, false)
})

function setTypologyByExternalEvent(e) {
	if (e) {
		store.commit('general/setTypology', e.detail.typology)
	}
}
function setFloorByExternalEvent(e) {
	if (e) {
		store.commit('general/setFloorFilter', e.detail.floor)
	}
}
</script>
<style lang="scss">
@use "sass:meta";
mobysuite-quote {
	font-size: 14px;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	word-break: normal;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	padding: 0;
	margin: 0;
	background-repeat: no-repeat;
	@include meta.load-css('@/scss/variables');
	.v-list--dense .v-list-item .v-list-item__subtitle,
	.v-list--dense .v-list-item .v-list-item__title,
	.v-list-item--dense .v-list-item__subtitle,
	.v-list-item--dense .v-list-item__title,
	.v-label {
		font-size: 14px !important;
		font-weight: normal !important;
		line-height: 18px !important;
		padding-right: 5px;
	}
	div {
		line-height: normal;
	}
	.row {
		--bs-gutter-x: 0;
		--bs-gutter-y: 0;
	}
	.col {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.v-card__title {
		font-size: 20px !important;
	}
	.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
	.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
	.v-application .text-body-2 {
		font-size: 14px !important;
	}
	h2 {
		font-size: 24px !important;
	}
	.v-application .text-body-1 {
		font-size: 14px !important;
		line-height: 24px !important;
	}
	.v-application .subtitle-1 {
		font-size: 16px !important;
		line-height: 30px !important;
	}
	.v-application .text-h6 {
		font-size: 20px !important;
	}
	.v-application .text-h5 {
		font-size: 24px !important;
	}
	.v-application .text-h4 {
		font-size: 30px !important;
	}
	h1 {
		font-size: 30px !important;
	}
	.v-application .text-sm-h6 {
		font-size: 20px !important;
	}
	.v-card__subtitle,
	.v-card__text {
		font-size: 12px !important;
		line-height: 18px !important;
	}
	form input {
		min-height: 26px !important;
		border: none !important;
	}
	[type='text'],
	[type='tel'] {
		min-height: 22px !important;
		font-size: 1em !important;
		border: none !important;
		background: transparent !important;
	}
	.v-input {
		border: none !important;
	}
	.v-application .text-subtitle-1 {
		font-size: 16px !important;
	}
	.v-btn.v-size--default {
		font-size: 14px !important;
	}
	.v-btn.v-size--large {
		font-size: 14px !important;
	}
	@include meta.load-css('@/scss/main');
}
</style>
