<template>
	<div>
		<div :class="{ 'image-dialog': true, 'text-center': thumbStyle }">
			<img
				ref="thumb"
				class="image-dialog-thumb resp-img"
				:src="thumb"
				:style="thumbStyle"
				@click="showDialog"
			/>
			<transition name="dialog" @enter="enter" @leave="leave">
				<div
					v-show="appearedDialog"
					ref="dialog"
					class="image-dialog-background"
				>
					<button
						class="image-dialog-close"
						type="button"
						aria-label="Close"
						@click="hideDialog"
					></button
					><img
						ref="animate"
						class="image-dialog-animate"
						:class="{ loading: !loaded }"
						:src="loaded ? full : thumb"
					/><img
						ref="full"
						class="image-dialog-full"
						:src="appearedDialog &amp;&amp; full"
						:width="`${mobile ? '100%' : '800px'}`"
						@load="onLoadFull"
					/>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Imagedialog',
	props: {
		thumb: {
			type: String,
			default: null,
		},
		thumbStyle: {
			type: String,
			default: null,
		},
		full: {
			type: String,
			default: null,
		},
		mobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loaded: false,
			appearedDialog: false,
		}
	},
	methods: {
		showDialog() {
			this.appearedDialog = true
		},
		hideDialog() {
			this.appearedDialog = false
		},
		enter() {
			this.animateImage(this.$refs.thumb, this.$refs.full)
		},
		leave() {
			this.animateImage(this.$refs.full, this.$refs.thumb)
		},
		onLoadFull() {
			this.loaded = true
		},
		animateImage(startEl, destEl) {
			const start = this.getBoundForDialog(startEl)
			this.setStart(start)

			this.$nextTick(() => {
				const dest = this.getBoundForDialog(destEl)
				this.setDestination(start, {
					top: dest.top,
					left: dest.left,
					width: dest.width || this.fullWidth,
					height: dest.height || this.fullHeight,
				})
			})
		},

		getBoundForDialog(el) {
			const bound = el.getBoundingClientRect()
			const dialog = this.$refs.dialog
			return {
				top: bound.top + dialog.scrollTop,
				left: bound.left + dialog.scrollLeft,
				width: bound.width,
				height: bound.height,
			}
		},

		setStart(start) {
			const el = this.$refs.animate
			el.style.left = start.left + 'px'
			el.style.top = start.top + 'px'
			el.style.width = start.width + 'px'
			el.style.height = start.height + 'px'
			el.style.transitionDuration = '0s'
			el.style.transform = ''
		},

		setDestination(start, dest) {
			const el = this.$refs.animate
			el.style.transitionDuration = ''

			const translate = this.mobile
				? `translate(0px, ${dest.top - start.top}px)`
				: `translate(${dest.left - start.left}px, ${dest.top - start.top}px)`
			const scale = this.mobile
				? 'scale(1)'
				: `scale(${dest.width / start.width}, ${dest.height / start.height})`
			el.style.transform = `${translate} ${scale}`
		},
	},
}
</script>
<style lang="scss" scoped>
.image-dialog-trigger {
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
}
.image-dialog-close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 60px;
	height: 60px;
	padding: 0;
	background: none;
	border: none;
	cursor: pointer;
	transition: 300ms ease-out;
	outline: none;
}
.image-dialog-close::before,
.image-dialog-close::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -0.5px;
	margin-left: -20px;
	width: 40px;
	height: 1px;
	background-color: #000;
}
.image-dialog-close::before {
	transform: rotate(45deg);
}
.image-dialog-close::after {
	transform: rotate(135deg);
}
.image-dialog-close:hover {
	transform: rotate(270deg);
}
.image-dialog-background {
	overflow: auto;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 80px 40px;
	background-color: rgba(255, 255, 255, 0.9);
	text-align: center;
	z-index: 9999;
}
.image-dialog-animate {
	display: none;
	position: absolute;
	transform-origin: left top;
}
.image-dialog-animate.loading {
	display: block;
}

.dialog-enter-active,
.dialog-leave-active {
	transition: background-color 300ms ease-out;
}
.dialog-enter,
.dialog-leave-to {
	background-color: rgba(255, 255, 255, 0);
}
.dialog-enter-active .image-dialog-animate,
.dialog-leave-active .image-dialog-animate {
	display: block;
	transition: transform 300ms cubic-bezier(1, 0, 0.7, 1);
}
.dialog-enter-active .image-dialog-full,
.dialog-leave-active .image-dialog-full {
	visibility: hidden;
}
.resp-img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
</style>
